* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;

    &::after,
    &::before {
      box-sizing: border-box;
    }
}

html {
    font-size: 16px;
}

body {
    min-width: 1200px;
    font-family: "HelveticaNeue", Helvetica, sans-serif, -apple-system;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    color: rgba(0, 0, 0, 0.87);
}



#app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn;
}
